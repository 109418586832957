import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../API/axiosInstance';
import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    TextField,
    Button,
    CircularProgress,
} from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FileViewer from './FileViewer';

const Chat = ({ channel }) => {
    const [messages, setMessages] = useState([]);
    const [error, setError] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState([]); // Change to an array to hold multiple files
    const endOfMessagesRef = useRef(null); // Reference for scrolling to the last message

    if (!channel) {
        return <Typography>No channel selected</Typography>;
    }

    useEffect(() => {
        const fetchMessages = async () => {
            setError(null);
            setLoading(true);
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) throw new Error('Token not available.');

                const response = await axiosInstance.get(`/api/message/channel/${channel._id}/messages`, {
                    headers: { Authorization: `Bearer ${token}` },
                });

                if (response.data.success && Array.isArray(response.data.messages)) {
                    setMessages(response.data.messages);
                } else {
                    throw new Error('Invalid response structure');
                }
            } catch (error) {
                setError(`Error fetching messages: ${error.response?.data?.message || error.message}`);
            } finally {
                setLoading(false);
            }
        };

        fetchMessages();
    }, [channel]);

    useEffect(() => {
        // Scroll to the last message whenever messages change
        endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }, [messages]);

    const handleSendMessage = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const token = localStorage.getItem('accessToken');
            if (!token) throw new Error('Token not available.');
            if (!newMessage.trim() && files.length === 0) throw new Error('Message content or file is required.');

            const formData = new FormData();
            formData.append('text', newMessage.trim());
            files.forEach(file => formData.append('files', file)); // Append all files to the formData

            const response = await axiosInstance.post(`/api/message/channel/${channel._id}/send`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                setMessages((prevMessages) => [...prevMessages, response.data.message]);
                setNewMessage('');
                setFiles([]); // Clear files after sending
            } else {
                throw new Error('Failed to send message');
            }
        } catch (error) {
            setError(`Error sending message: ${error.response?.data?.message || error.message}`);
        }
    };

    const handleDeleteMessage = async (messageId) => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) throw new Error('Token not available.');

            await axiosInstance.delete(`/api/message/channel/${channel._id}/message/${messageId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            setMessages((prevMessages) => prevMessages.filter((message) => message._id !== messageId));
        } catch (error) {
            setError(`Error deleting message: ${error.response?.data?.message || error.message}`);
        }
    };

    if (loading) return <CircularProgress />;
    if (error) return <Typography color="error">Error: {error}</Typography>;

    return (
        <Box 
            sx={{
                p: 1,
                bgcolor: 'grey.300',
                borderRadius: 1,
                boxShadow: 2,
                display: 'flex',
                flexDirection: 'column',
                height: '90vh',
                maxHeight: '80vh',
                width: '100%',
                overflow: 'hidden',
            }}
        >
            <List 
                sx={{ 
                    flexGrow: 1, 
                    overflowY: 'auto',
                    maxHeight: 'calc(100vh - 100px)',
                    padding: 0,
                    bgcolor: 'white',
                }}
            >
                {messages.length > 0 ? (
                    messages.map((message) => (
                        <ListItem key={message.message_id} sx={{ display: 'flex', flexDirection: 'row', mb: 0.5, width: '100%', bgcolor: 'white' }}>
                            <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                                <ListItemText 
                                    primary={<Typography variant="body1" sx={{ wordBreak: 'break-word' }}>{message.content || 'No content available'}</Typography>} 
                                />
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', ml: 2 }}>
                                {message.fileUrl && (
                                    <Box sx={{ textAlign: 'right' }}>
                                        <Typography>Attachment:</Typography>
                                        <FileViewer fileUrl={message.fileUrl} />
                                        <Button 
                                            variant="outlined" 
                                            href={`${message.fileUrl}?wrap=0`} 
                                            target="_blank" 
                                            rel="noopener noreferrer"
                                            sx={{ mt: 1 }}
                                        >
                                            Download File
                                        </Button>
                                    </Box>
                                )}
                                
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <Button 
                                        onClick={() => handleDeleteMessage(message.message_id)} 
                                        aria-label="Delete message" 
                                        color="error"
                                        sx={{ 
                                            minWidth: '10px', 
                                            width: '20px', 
                                            height: '20px', 
                                            marginLeft: '10px', 
                                            borderRadius: '50%', 
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        x
                                    </Button>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                        <Typography variant="caption" sx={{ fontSize: '0.4rem' }}>{`Creator: ${message.user_id?.username || 'Unknown User'}`}</Typography>
                                        <Typography variant="caption" sx={{ fontSize: '0.4rem' }}>{`Created At: ${new Date(message.timestamp).toLocaleString()}`}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </ListItem>
                    ))
                ) : (
                    <Typography>No messages available</Typography>
                )}
                
                {/* Add a div to mark the end of messages */}
                <div ref={endOfMessagesRef} />
            </List>

            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: '8px' }}>
                <Button 
                    variant="contained" 
                    component="label" 
                    sx={{
                        width: 'auto', // Or specify a width like '100px'
                        height: '40px', // Fixed height
                        display: 'flex', // To center content
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center', // Center vertically
                        backgroundColor: 'white',
                    }}                
                >
                    <AttachFileIcon sx={{ fontSize: '20px', color: 'black' }} />
                    <input 
                        type="file" 
                        accept="*/*" 
                        hidden 
                        multiple // Allow multiple file selection
                        onChange={(e) => setFiles([...e.target.files])} // Update to accept multiple files
                    />
                </Button>
                
                <TextField
                    variant="outlined"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSendMessage(e);
                        }
                    }}
                    placeholder="Type your message..."
                    required
                    fullWidth
                    sx={{
                        width: '80%', 
                        height: '40px', 
                        mx: 1, 
                        '& .MuiOutlinedInput-root': {
                            height: '100%', 
                            display: 'flex', 
                            alignItems: 'center', 
                        },
                        '& input': {
                            padding: '10px',
                        },
                    }}
                />
                
                <Button 
                    type="submit" 
                    variant="contained" 
                    sx={{
                        width: 'auto', // Or specify a width like '100px'
                        height: '40px', // Fixed height
                        display: 'flex', // To center content
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center', // Center vertically
                    }}
                    onClick={handleSendMessage}
                >
                    Send
                </Button>
            </Box>
        </Box>
    );
};

export default Chat;
