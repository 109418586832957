import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Box, Typography } from '@mui/material';

const UserProfile = () => {
    const { user } = useAuth();

    if (!user) {
        return <div>Loading...</div>;
    }

    return (
        <Box className="UserProfile" sx={{ lineHeight: '0.01', mb: 0.01 }}>
            <Typography variant="body2" component="h2" sx={{ mb: 0.5, fontSize: '0.75rem' }}>
                User Profile
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.25, fontSize: '0.75rem' }}>
                ID: {user.id}
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.25, fontSize: '0.75rem' }}>
                Username: {user.username || 'No username available'}
            </Typography>
            <Typography variant="body2" sx={{ mb: 0.25, fontSize: '0.75rem' }}>
                Email: {user.email}
            </Typography>
        </Box>
    );
};

export default UserProfile;
