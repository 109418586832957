import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Dashboard from './components/Dashboard';
import Profile from './components/Profile';
import Login from './components/Login';
import Signup from './components/Signup';
import ResetPassword from './components/ResetPassword'; // Import the ResetPassword component
import PrivateRoute from './routes/PrivateRoute';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme'; // Adjust the path according to your project structure

const App = () => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen((prevState) => !prevState);
    };

    return (
        <ThemeProvider theme={theme}> {/* Wrap your application in ThemeProvider */}
            <AuthProvider>
                <Router>
                    <div className="App">
                        <Routes>
                            <Route path="/" element={<Navigate to="/dashboard" />} />
                            <Route path="/login" element={<Login />} />
                            <Route path="/signup" element={<Signup />} />
                            <Route path="/reset-password" element={<ResetPassword />} /> {/* Add the Reset Password route */}
                            <Route path="/dashboard" element={<PrivateRoute element={<Dashboard toggleSidebar={toggleSidebar} />} />} />
                            <Route path="/profile" element={<PrivateRoute element={<Profile />} />} />
                        </Routes>
                    </div>
                </Router>
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
