import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Box, Container, CssBaseline, Typography } from '@mui/material';
import Sidebar from './Sidebar';
import Header from './Header';
import Chat from './Chat';
import Footer from './Footer'; // Import the Footer component

const Dashboard = () => {
    const { user } = useAuth();
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [activeChannel, setActiveChannel] = useState(null);
    const noMessagesMessage = "No messages available.";

    // Set active channel based on last used channel ID
    useEffect(() => {
        if (user) {
            const lastChannelId = localStorage.getItem('lastUsedChannelId');
            if (lastChannelId) {
                setActiveChannel({ _id: lastChannelId });
            }
        }
    }, [user]);

    // Toggle sidebar open/close state
    const toggleSidebar = () => {
        setSidebarOpen((prevState) => !prevState);
    };

    // Handle channel selection and update local storage
    const handleChannelSelect = (channel) => {
        setActiveChannel(channel);
        localStorage.setItem('lastUsedChannelId', channel._id);
        if (window.innerWidth <= 768) {
            setSidebarOpen(false); // Close sidebar on small screens
        }
    };

    // Handle window resize to manage sidebar visibility
    const handleResize = () => {
        setSidebarOpen(window.innerWidth > 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const isAuthenticated = !!user;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <CssBaseline />
            
            {/* Header */}
            <Header 
                isSidebarOpen={isSidebarOpen} 
                toggleSidebar={toggleSidebar} 
                noMessagesMessage={noMessagesMessage} 
            />

            {/* Main Content Area */}
            <Box sx={{ display: 'flex', flex: 1 }}>
                {/* Sidebar */}
                <Sidebar
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSidebar}
                    onChannelSelect={handleChannelSelect}
                />

                {/* Chat Area */}
                <Container component="main" sx={{ flex: 1, mt: 2, overflowY: 'auto' }}>
                    {activeChannel ? (
                        <Box>
                            <Typography variant="h5">Chat: {activeChannel.name}</Typography>
                            <Chat channel={activeChannel} />
                        </Box>
                    ) : (
                        <Typography>{noMessagesMessage}</Typography>
                    )}
                </Container>
            </Box>

            {/* Footer */}
            <Footer isAuthenticated={isAuthenticated} /> {/* Use the Footer component */}
        </Box>
    );
};

export default Dashboard;
