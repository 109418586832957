import React, { useState } from 'react';
import useChannels from '../hooks/hooksChannels.js';
import { useAuth } from '../contexts/AuthContext';
import axiosInstance from '../API/axiosInstance';
import {
    Box,
    Button,
    Typography,
    List,
    ListItem,
    ListItemText,
    TextField,
    Modal,
} from '@mui/material';

const Sidebar = ({ isSidebarOpen, toggleSidebar, onChannelSelect }) => {
    const { user } = useAuth();
    const [activeChannel, setActiveChannel] = useState(null);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [showCreateChannel, setShowCreateChannel] = useState(false);
    const { channels, error, setChannels, setError } = useChannels();

    const handleChannelSelect = (channel) => {
        setActiveChannel(channel);
        onChannelSelect(channel);
    };

    const createChannel = async () => {
        if (!name) return;

        try {
            const channelData = { name, description: description.trim() };
            const response = await axiosInstance.post('/api/channel', channelData);
            setChannels((prev) => [...prev, response.data]);
            setName('');
            setDescription('');
            setShowCreateChannel(false);
        } catch (err) {
            const errorMessage =
                err.response?.status === 404
                    ? 'Endpoint not found.'
                    : err.message.includes('CORS')
                    ? 'CORS error. Check server settings.'
                    : 'Failed to create channel.';
            setError(errorMessage);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            createChannel();
        }
    };

    const deleteChannel = async (channelId) => {
        try {
            await axiosInstance.delete(`/api/channel/${channelId}`);
            setChannels((prev) => prev.filter((channel) => channel._id !== channelId));
            if (activeChannel?._id === channelId) {
                setActiveChannel(null);
                onChannelSelect(null);
            }
        } catch (err) {
            setError(err.response?.status === 404 ? 'Channel not found.' : 'Failed to delete channel.');
        }
    };

    return (
        <Box
            sx={{
                width: isSidebarOpen ? 250 : 0,
                height: '100vh',
                maxHeight: '80vh',
                transition: 'width 0.3s',
                overflow: 'hidden',
                bgcolor: 'background.default',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Button
                variant="outlined"
                onClick={() => setShowCreateChannel(!showCreateChannel)}
                sx={{ margin: 2, width: '80%' }}
            >
                {showCreateChannel ? '✕' : '＋ Channel'}
            </Button>

            {isSidebarOpen && (
                <>
                    {error && (
                        <Typography color="error" sx={{ mb: 2 }}>
                            {error}
                        </Typography>
                    )}

                    <List sx={{ overflowY: 'auto', flexGrow: 1, maxHeight: 'calc(100vh - 100px)' }}>
                        {channels.map((channel) => (
                            <ListItem
                                key={channel._id}
                                onClick={() => handleChannelSelect(channel)}
                                sx={{
                                    justifyContent: 'flex-start',
                                    bgcolor: activeChannel?._id === channel._id ? 'primary.active' : 'transparent',
                                    '&:hover': {
                                        bgcolor: 'rgba(25, 118, 210, 0.1)', // Change the background color on hover
                                    },
                                    cursor: 'pointer',
                                    textAlign: 'left',
                                    padding: 1,
                                }}
                            >
                                <ListItemText
                                    primary={
                                        <span
                                            style={{
                                                color: activeChannel?._id === channel._id ? 'primary.main' : 'text.primary',
                                                fontWeight: activeChannel?._id === channel._id ? 'bold' : 'normal',
                                                width: '100%',
                                                textAlign: 'left',
                                                display: 'block',
                                            }}
                                        >
                                            {channel.name}
                                        </span>
                                    }
                                    secondary={channel.description || '_'}
                                    sx={{
                                        textAlign: 'left',
                                    }}
                                />
                                <Button
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent triggering channel select on delete button click
                                        deleteChannel(channel._id);
                                    }}
                                    aria-label={`Delete ${channel.name}`}
                                    color="error"
                                    sx={{
                                        borderRadius: '50%',
                                        minWidth: '30px',
                                        width: '30px',
                                        height: '30px',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    🗑️
                                </Button>
                            </ListItem>
                        ))}
                    </List>

                    <Modal
                        open={showCreateChannel}
                        onClose={() => setShowCreateChannel(false)}
                        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                p: 4,
                                borderRadius: 2,
                                boxShadow: 24,
                                width: 300,
                            }}
                        >
                            <Button
                                onClick={() => setShowCreateChannel(false)}
                                sx={{ position: 'absolute', right: 10, top: 10 }}
                                aria-label="Close modal"
                            >
                                ✕
                            </Button>
                            <Typography variant="h6">Create New Channel</Typography>
                            <TextField
                                label="Channel Name"
                                variant="outlined"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                onKeyDown={handleKeyDown}
                                fullWidth
                                sx={{ mt: 2 }}
                            />
                            <TextField
                                label="Channel Description (optional)"
                                variant="outlined"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                onKeyDown={handleKeyDown}
                                fullWidth
                                sx={{ mt: 2 }}
                            />
                            <Button
                                onClick={createChannel}
                                disabled={!name}
                                variant="contained"
                                sx={{ mt: 2 }}
                            >
                                Create Channel
                            </Button>
                        </Box>
                    </Modal>
                </>
            )}
        </Box>
    );
};

export default Sidebar;
