import React from 'react';

const FileViewer = ({ fileUrl }) => {
    // Append ?wrap=0 to the file URL
    const modifiedFileUrl = `${fileUrl}?wrap=0`;

    // Get the file extension to determine how to render it
    const fileExtension = modifiedFileUrl.split('.').pop().toLowerCase();

    const renderFileViewer = () => {
        switch (fileExtension) {
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                // Render the image directly
                return (
                    <img 
                        src={modifiedFileUrl} 
                        alt="Preview" 
                        style={{ 
                            width: '200px', // Set a fixed width for the preview
                            height: 'auto', // Maintain aspect ratio
                            borderRadius: '5px',
                            boxShadow: '0 2px 5px rgba(0,0,0,0.3)',
                            marginTop: '10px'
                        }} 
                    />
                );
            case 'pdf':
                return <embed src={modifiedFileUrl} type="application/pdf" width="200" height="300" />;
            case 'txt':
                return <iframe src={modifiedFileUrl} width="200" height="300" title="Text File Viewer" />;
            case 'mp4':
                return (
                    <video controls width="200">
                        <source src={modifiedFileUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                );
            case 'mp3':
                return (
                    <audio controls>
                        <source src={modifiedFileUrl} type="audio/mpeg" />
                        Your browser does not support the audio element.
                    </audio>
                );
            default:
                return (
                    <a href={modifiedFileUrl} target="_blank" rel="noopener noreferrer">
                        Download File
                    </a>
                );
        }
    };

    return (
        <div>
            {renderFileViewer()}
        </div>
    );
};

export default FileViewer;
