// theme.js (or wherever you define your theme)
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#6400ff',  // Primary color
            dark: '#115293',   // Darker shade for primary
            active: '#e0f7fa', // Light cyan for active background
        },
        text: {
            primary: '#000',   // Primary text color
            secondary: '#666',  // Secondary text color
        },
    },
    typography: {
        activeFontWeight: 'bold', // Font weight for active items
        textDecoration: 'none',    // Default text decoration for buttons
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 20, // Set default border radius for all buttons
                    textDecoration: 'none', // Ensure no text decoration by default
                    '&:hover': {
                        backgroundColor: 'rgba(100, 0, 255, 0.08)', // Light hover effect
                    },
                    '&.active': {
                        backgroundColor: 'rgba(100, 0, 255, 0.2)', // Use the active background color defined in the palette
                        fontWeight: 'bold', // Bold font weight when active
                    },
                },
            },
        },
    },
});

export default theme;
