import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import UserProfile from './UserProfile';
import Nav from './Nav';
import { Box, Button, Typography } from '@mui/material';

const Header = ({ isSidebarOpen, toggleSidebar, noMessagesMessage }) => {
    const { user } = useAuth();

    return (
        <Box 
            sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between', 
                height: '60px', // Set height of the header
                p: 2, 
                bgcolor: '#D3D3D3', // Light gray background
                color: 'black' // Change text color to black for better contrast
            }}
        >
            {/* Sidebar toggle button with smaller width */}
            <Button
                onClick={toggleSidebar}
                variant="outlined"
                sx={{
                    color: 'black', // Change button text color to black
                    borderColor: 'black', // Change button border color to black
                    width: '50px', // Set a fixed width for the button
                    height: '40px', // Set a fixed height for the button
                    '&:hover': {
                        borderColor: 'black',
                        backgroundColor: 'rgba(0, 0, 0, 0.1)',
                    },
                }}
            >
                {isSidebarOpen ? '☰' : '→'} {/* Icon or text for toggle */}
            </Button>

            {/* Header title and UserProfile */}


            {/* Navbar positioned to the right */}
            <nav>
                <Nav />
            </nav>
        </Box>
    );
};

export default Header;
